import React, { useEffect, useRef, useContext } from "react";
import Drawer from "@mui/material/Drawer";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import IconButton from "@mui/material/IconButton";
import LoginIcon from "@mui/icons-material/Login";
import PuzzleImageContext from '../puzzleImageContext';
import { Auth } from "firebase/auth";
import { Firestore } from "firebase/firestore";
import Logo from "../assets/GK Logo.png"

interface NavProps {
  auth: Auth;
  db: Firestore;
}

export default function Navbar({ auth, db }: NavProps) {
  const { puzzleImageUrl } = useContext(PuzzleImageContext);
  const navigate = useNavigate(); // Initialize the navigate function
  const handleLoginClick = () => {
    navigate("/login");
  };
  const handleHomeClick = () => {
    navigate("/");
  };
  let userIsLoggedIn = false;
  if (auth.currentUser) {
    userIsLoggedIn = true;
  } else {
    userIsLoggedIn = false;
  }
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 64,
        [`& .MuiDrawer-paper`]: {
          width: 64,
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          bgcolor: "transparent",
        },
      }}
    >
      <Box
        sx={{
          p: 2,
          flexDirection: "column",
          alignItems: "center",
          display: { xs: "flex", xl: "flex" }
        }}
      >
        {!userIsLoggedIn && (
          <>
            {puzzleImageUrl && <img
              src={puzzleImageUrl}
              alt="Logo"
              style={{ width: 35, height: 35, cursor: "pointer" }}
              onClick={handleHomeClick}
            />
            }
          </>
        )}
        {userIsLoggedIn && (
          <>
            <img
              src={Logo}
              alt="Logo"
              style={{ width: 30, height: 30, cursor: "pointer" }}
              onClick={handleHomeClick}
            />
          </>
        )}
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {!userIsLoggedIn && (
          <IconButton onClick={handleLoginClick}>
            <LoginIcon />
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <IconButton
          component="a"
          href="https://github.com/GorgonUK"
          target="_blank"
        >
          <GitHubIcon />
        </IconButton>
        <IconButton
          component="a"
          href="https://www.linkedin.com/in/georgekontus"
          target="_blank"
        >
          <LinkedInIcon />
        </IconButton>
      </Box>
    </Drawer>
  );
}
