import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  Auth,
} from "firebase/auth";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Logo from "../assets/GK Logo.png";
import { LinearProgress } from "@mui/material";
import imageData from "../../src/assets/collection.json"

interface LoginProps {
  auth: Auth;
  provider: GoogleAuthProvider;
}

function Login({ auth, provider }: LoginProps) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const allowedEmails = process.env.REACT_APP_ALLOWED_EMAILS!.split(',');

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const result = await signInWithPopup(auth, provider);
      const userEmail = result.user.email;
      if (userEmail && allowedEmails.includes(userEmail)) {
        navigate("/admin");
      } else {
        await signOut(auth);
        alert("Access denied. You are not authorized to access this page.");
        navigate("/");
      }
    } catch (error) {
      console.error(error);
      navigate("/");
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    const canvas = canvasRef.current;

    if (canvas && canvas.getContext) {
      const ctx = canvas.getContext("2d");

      if (ctx) {
        const images = imageData.map((item: { name: any; }) => `assets/collection/${item.name}`);
        const randomIndex = Math.floor(Math.random() * images.length);
        const randomImage = require(`../${images[randomIndex]}`);
  
        const loadImage = (src: string): Promise<HTMLImageElement> => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = src;
            img.onload = () => resolve(img);
            img.onerror = reject;
          });
        };
  
        Promise.all([
          loadImage(Logo),
          loadImage(randomImage),
        ]).then(([logo, backgroundImage]) => {
          const padding = 60;
          const maxCanvasWidth = canvas.parentElement?.clientWidth ?? 500;
          const aspectRatio = logo.naturalWidth / logo.naturalHeight;

          let canvasWidth = logo.naturalWidth + padding * 2;
          let logoWidth = logo.naturalWidth;
          let logoHeight = logo.naturalHeight;

          if (canvasWidth > maxCanvasWidth) {
            canvasWidth = maxCanvasWidth;
            logoWidth = maxCanvasWidth - padding * 2;
            logoHeight = logoWidth / aspectRatio;
          }

          const canvasHeight = logoHeight + padding * 2;

          canvas.width = canvasWidth;
          canvas.height = canvasHeight;

          // Draw the logo
          const x = (canvasWidth - logoWidth) / 2;
          const y = (canvasHeight - logoHeight) / 2;
          ctx.drawImage(logo, x, y, logoWidth, logoHeight);

          // Set the global composite operation
          ctx.globalCompositeOperation = 'source-in';

          // Draw the background image, it will clip to the logo
          ctx.drawImage(backgroundImage, 0, 0, canvasWidth, canvasHeight);
        }).catch(error => {
          console.error("Error loading images:", error);
        });
      }
    }
  }, [navigate, auth]);

  if (isLoading) {
    return (
      <Box height="100vh" width="100%">
      <LinearProgress sx={{backgroundColor: '#303030', "& .MuiLinearProgress-bar": { backgroundColor: '#FFF' },height: '2px' }}/>
      </Box>
    );
  }

  if(auth.currentUser){
    navigate("/admin");
  }
  return (
    <>
      <Grid
        container
        component="main"
        sx={{
          height: "100vh",
          p: 0,
        }}
      >
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          sx={{
            position: "relative",
            backgroundColor: "transparent",
            width: "100%",
            display: {
              xs: "none",
              sm: "none",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
            justifyContent: "center",
            alignItems: "center",
            zIndex: 0,
            height: "100vh",
          }}
        >
          <canvas
            ref={canvasRef}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          component={Paper}
          elevation={6}
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            position: "relative",
            zIndex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "white" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleLogin}
            >
              Login with Google
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Login;
