import React, { useState } from 'react';
import { TextField, FormControlLabel, Checkbox, Button, Stack, Box, InputAdornment, Typography } from '@mui/material';
import TaxCalculator from 'tax-calculator-uk';

interface TaxOptions {
    age: number;
    studentLoanPlan: number;
    blind: boolean;
    pensionPercentage: number;
}

const UKIncomeTaxCalculator: React.FC = () => {
    const [income, setIncome] = useState<number | ''>('');
    const [options, setOptions] = useState<TaxOptions>({
        age: 25,
        studentLoanPlan: 0,
        blind: false,
        pensionPercentage: 5,
    });
    const [taxBreakdown, setTaxBreakdown] = useState<any>(null);

    const handleCalculate = () => {
        if (typeof income === 'number') {
            const incomeTax = TaxCalculator(income, options).getTaxBreakdown();
            setTaxBreakdown(incomeTax);
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
                label="Annual Income (£)"
                type="number"
                variant="outlined"
                value={income}
                onChange={(e) => setIncome(parseFloat(e.target.value) || '')}
            />
            <TextField
                label="Age"
                type="number"
                variant="outlined"
                value={options.age}
                onChange={(e) => setOptions({ ...options, age: parseInt(e.target.value) })}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={options.studentLoanPlan !== 0}
                        onChange={(e) => setOptions({ ...options, studentLoanPlan: e.target.checked ? 1 : 0 })}
                    />
                }
                label="Student Loan Plan"
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={options.blind}
                        onChange={(e) => setOptions({ ...options, blind: e.target.checked })}
                    />
                }
                label="Blind"
            />
            <TextField
                label="Pension Percentage (0 if not known)"
                type="number"
                variant="outlined"
                value={options.pensionPercentage}
                onChange={(e) => setOptions({ ...options, pensionPercentage: parseInt(e.target.value) })}
            />
            <Button variant="contained" onClick={handleCalculate}>
                Calculate Tax
            </Button>
            {taxBreakdown && (
                <>
                <Typography>Calculated take home pay is below</Typography>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 3, sm: 4, md: 3 }}
                    sx={{ width: '100%', my: 2 }}
                    flexWrap="wrap"
                >
                    <TextField
                        label="Net Yearly Income"
                        value={taxBreakdown.netIncome?.yearly?.toFixed(2) ?? ''}
                        InputProps={{
                            readOnly: true,
                            startAdornment: <InputAdornment position="start">£</InputAdornment>,
                        }}
                        sx={{ width: { xs: '100%', sm: '50%', md: '25%' } }}
                    />
                    <TextField
                        label="Net Monthly Income"
                        value={taxBreakdown.netIncome?.monthly?.toFixed(2) ?? ''}
                        InputProps={{
                            readOnly: true,
                            startAdornment: <InputAdornment position="start">£</InputAdornment>,
                        }}
                        sx={{ width: { xs: '100%', sm: '50%', md: '25%' } }}
                    />
                    <TextField
                        label="Net Daily Income"
                        value={taxBreakdown.netIncome?.daily?.toFixed(2) ?? ''}
                        InputProps={{
                            readOnly: true,
                            startAdornment: <InputAdornment position="start">£</InputAdornment>,
                        }}
                        sx={{ width: { xs: '100%', sm: '50%', md: '25%' } }}
                    />
                    <TextField
                        label="Net Hourly Income"
                        value={taxBreakdown.netIncome?.daily ? (taxBreakdown.netIncome.daily / 8).toFixed(2) : ''}
                        InputProps={{
                            readOnly: true,
                            startAdornment: <InputAdornment position="start">£</InputAdornment>,
                        }}
                        sx={{ width: { xs: '100%', sm: '50%', md: '25%' } }}
                    />
                </Stack>
</>
            )}
        </Box>
    );
};

export default UKIncomeTaxCalculator;
