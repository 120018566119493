import React from "react";
import { Box, Typography, Paper } from "@mui/material";
import { useTheme } from '@mui/material/styles';

export default function AboutMe() {
    const theme = useTheme();
  return (
    <Box component="main" >
      <Paper elevation={3} sx={{ p: { xs:0,md:3,lg:3,xl:3},pt:{xs:3},mb:3, background: "#272727" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            p: { xs:0,md:3,lg:3,xl:3},
            alignItems: "center",
          }}
        >
             <Box
            component="img"
            sx={{
              height: "160px",
              flexShrink: 0,
              display: { xs: "block", sm: "block", md: "block", xl: "block" },
            }}
            src="https://media.licdn.com/dms/image/D4E03AQHDMQ-HLvpuHw/profile-displayphoto-shrink_400_400/0/1674687368566?e=1709164800&v=beta&t=EFGa2FxDP2YTffq1osyvlOIBkaAQRiuwt5dRul93kd0"
            alt="George Kontus"
          />
          <Box sx={{ flexGrow: 1, flexBasis: 0, p: 2 }}>
            <Typography variant="h4" gutterBottom>
              Hello, I'm George Kontus
            </Typography>
            <Typography variant="subtitle1" color={theme.palette.primary.main} gutterBottom>
              D365 Developer
            </Typography>
            <Typography variant="body1">
              I pride myself on being able to solve complex problems with great
              digital experiences. Ensuring solutions are delivered to a high
              standard with a great user experience that meets the needs and
              wants of all users. I thrive while working in an Agile environment
              including design, development and testing of enterprise systems.
            </Typography>
          </Box>
         
        </Box>
      </Paper>
    </Box>
  );
}
