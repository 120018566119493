import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider, ThemeOptions } from '@mui/material/styles';

// Extension of the theme structure
declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // Allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

// Theme options definition
export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#FF8626',
      contrastText: '#ffffff',
      light: '#ff7b2a',
      dark: '#ff6432',
    },
    secondary: {
      main: '#f50057',
    },
    warning: {
      main: '#dc8f14',
    },
    background: {
      default: '#303030',
      paper: '#d76e1a',
    },
  },
  status: {
    danger: '#e53e3e',
  },
};

// Create theme using the options
const theme = createTheme(themeOptions);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
