import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Navbar from "./components/navbar";
import ProjectShowcase from "./components/projects";
import Login from "./components/login";
import AdminPanel from "./components/adminPanel";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import JigSawPuzzle from "./components/jigsaw-puzzle"
import PuzzleImageContext from './puzzleImageContext';
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);
function App() {
  const [puzzleImageUrl, setPuzzleImageUrl] = useState('');
  return (
    <PuzzleImageContext.Provider value={{ puzzleImageUrl, setPuzzleImageUrl }}>
    <Router>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Navbar auth={auth} db={db}/>
        <Routes>
          <Route path="/" element={<MainContent />} />
          <Route
            path="/login"
            element={<Login auth={auth} provider={provider} />}
          />
          <Route path="/admin" element={<AdminPanel auth={auth} db={db} />} />{" "}
        </Routes>
      </Box>
    </Router>
    </PuzzleImageContext.Provider>
  );
}

function MainContent() {
  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <JigSawPuzzle />
        <ProjectShowcase />
      </Box>
    </>
  );
}

export default App;
