import React, { useState, useRef, useEffect, useCallback } from "react";
import { CircularProgress, Snackbar, Alert } from '@mui/material';
import debounce from 'lodash.debounce';
import { useNavigate } from "react-router-dom";
import { Auth } from "firebase/auth";
import { signOut } from "firebase/auth";
import Autocomplete from '@mui/material/Autocomplete';
import { AutocompleteOption, Property } from '../helpers/interfaces';
import { Box, Link, TextField, Typography, IconButton } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import PropertyList from './propertyList'
import { Firestore, doc, setDoc, getDoc, updateDoc, CollectionReference, DocumentData, collection, getDocs, query } from "firebase/firestore";
import LoadingButton from '@mui/lab/LoadingButton';
import UKIncomeTaxCalculator from './tax';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


interface AdminPanelProps {
  auth: Auth;
  db: Firestore;
}
function AdminPanel({ auth, db }: AdminPanelProps) {
  const navigate = useNavigate();
  const [autocompleteOptions, setAutocompleteOptions] = useState<AutocompleteOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [isSelecting, setIsSelecting] = useState(false);
  const [properties, setProperties] = useState<Property[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingFetchProperties, setloadingFetchProperties] = React.useState(false);
  const [error, setError] = useState<string | null>(null);
  const cache = useRef<{ [key: string]: AutocompleteOption[] }>({});
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const AntTabs = styled(Tabs)({
    borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-indicator': {
      backgroundColor: '#1890ff',
    },
  });

  const apiUrl = process.env.NODE_ENV === 'development'
    ? 'http://localhost:8888/.netlify/functions/'
    : '/.netlify/functions/';

  useEffect(() => {
    // Function to load cache from localStorage
    const loadCache = () => {
      const cacheData = localStorage.getItem('autocompleteCache');
      if (cacheData) {
        cache.current = JSON.parse(cacheData);
      }
    };

    loadCache();
  }, []);
  interface StyledTabProps {
    label: string;
  }
  const StyledTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
  ))(({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#fff',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#FF8627',
    },
  }));

  interface StyledTabsProps {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
  }

  const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
      {...props}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#FF8627',
    },
  });
  const fetchAutocompleteOptions = useCallback(debounce(async (searchTerm: string) => {
    if (!searchTerm.trim()) {
      setAutocompleteOptions([]);
      return;
    }

    // Check the cache first
    if (cache.current[searchTerm]) {
      setAutocompleteOptions(cache.current[searchTerm]);
      return;
    }

    try {
      const response = await fetch(`${apiUrl}autocomplete?search_term=${searchTerm}`);
      const data = await response.json();
      if (data.suggestions && Array.isArray(data.suggestions)) {
        cache.current[searchTerm] = data.suggestions as AutocompleteOption[];
        setAutocompleteOptions(data.suggestions);
        localStorage.setItem('autocompleteCache', JSON.stringify(cache.current));
      }
    } catch (error) {
      console.error(error);
    }
  }, 300), []);

  const fetchProperties = async () => {
    if (!selectedOption) {
      console.log('No area selected');
      return;
    }
    if (!auth.currentUser) {
      console.error('No user logged in');
      setError('User is not logged in.');
      setloadingFetchProperties(false);
      return;
    }

    setloadingFetchProperties(true);

    try {
      const response = await fetch(`${apiUrl}getProperties?area=${encodeURIComponent(selectedOption)}&userId=${auth.currentUser?.uid}`);
      if (response.ok) {
        await fetchPropertiesFromFirebase(db, auth.currentUser.uid, setProperties);
      } else {
      }
    } catch (error) {
      console.error('Error fetching properties:', error);
    } finally {
      setLoading(false);
      setloadingFetchProperties(false);
    }
  };

  const fetchPropertiesWrapper = async () => {
    if (auth.currentUser) {
      await fetchPropertiesFromFirebase(db, auth.currentUser.uid, setProperties);
    }
  };

  const handleSearchClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (auth.currentUser) {
      fetchProperties();
    }
  };
  const handleLogout = async () => {
    await signOut(auth);
    navigate("/");
  };
  const fetchPropertiesFromFirebase = async (db: Firestore, userId: string, setProperties: React.Dispatch<React.SetStateAction<Property[]>>) => {
    if (!userId) {
      console.log('No user logged in');
      return;
    }

    const propertiesCollectionRef = collection(db, `users/${userId}/properties`);

    try {
      const propertiesQuery = query(propertiesCollectionRef);
      const querySnapshot = await getDocs(propertiesQuery);
      let fetchedProperties: Property[] = [];

      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        if (docData && Array.isArray(docData.properties)) {
          // Combine properties from all chunks
          fetchedProperties = [...fetchedProperties, ...docData.properties as Property[]];
        }
      });

      setProperties(fetchedProperties);
    } catch (error) {
      console.error('Error fetching properties from Firebase:', error);
    }
  };

  useEffect(() => {
    if (auth.currentUser) {
      fetchPropertiesFromFirebase(db, auth.currentUser.uid, setProperties);
    } else {
      console.log('User not authenticated');
    }
  }, [db, auth.currentUser, setProperties]);
  const handleCloseSnackbar = () => setError(null);
  return (
    <Box sx={{ width: "100%", p: 2 }}>
      <ToastContainer />
      {loading && <CircularProgress />}
      {error && <Snackbar open={Boolean(error)} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error">{error}</Alert>
      </Snackbar>}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Typography variant="h4">Admin Dashboard</Typography>
        <IconButton onClick={handleLogout}>
          <LogoutIcon />
        </IconButton>
      </Box>
      <Box sx={{ bgcolor: 'transparent' }}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          <StyledTab label="Jobs" />
          <StyledTab label="UK Income Tax" />
          <StyledTab label="Properties" />
        </StyledTabs>
        <Box sx={{ p: 3 }} />
      </Box>
      {value === 2 && (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>

            <Autocomplete
              sx={{ width: "100%" }}
              freeSolo
              options={autocompleteOptions.map((option) => option.value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Places"
                  variant="outlined"
                  onChange={(e) => {
                    if (!isSelecting) {
                      fetchAutocompleteOptions(e.target.value);
                    }
                  }}
                />
              )}
              onInputChange={(event, newInputValue, reason) => {
                if (reason === 'input') {
                  fetchAutocompleteOptions(newInputValue);
                }
              }}
              onChange={(event, newValue) => {
                setSelectedOption(newValue ?? '');
              }}
            />
            <LoadingButton
              variant="contained"
              onClick={handleSearchClick}
              disabled={!selectedOption}
              loading={loadingFetchProperties}>
              Search
            </LoadingButton>          </Box>
          <PropertyList properties={properties} db={db} auth={auth} fetchPropertiesFromFirebase={fetchPropertiesWrapper} />
        </>)}
      {value === 0 && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Link href="https://jobhuntuk.com" underline="none">
            Find your next job on JobHunt UK
          </Link>

        </Box>
      )}
      {value === 1 && (
        <UKIncomeTaxCalculator />
      )}
    </Box>
  );
}

export default AdminPanel;
