import React, { useState, useEffect } from 'react';
import { CircularProgress, Stack, Box, Card, CardContent, CardMedia, Grid, Typography, Chip, Link, MenuItem, Select } from '@mui/material';
import BedroomIcon from '@mui/icons-material/BedroomParent';
import BathroomIcon from '@mui/icons-material/Bathroom';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PriceIcon from '@mui/icons-material/PriceCheck';
import { EventNote, List, AccessTime, Group, Visibility, ConfirmationNumber } from '@mui/icons-material';
import Carousel from 'react-material-ui-carousel';
import { Dialog, IconButton, Collapse, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Property } from '../helpers/interfaces';
import Slider from '@mui/material/Slider';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { GoogleMap, LoadScriptNext, Marker, InfoWindow } from '@react-google-maps/api';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SelectChangeEvent, InputLabel } from '@mui/material';
import { Firestore, collection, deleteDoc, doc, getDocs, updateDoc } from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Auth } from 'firebase/auth';

interface PropertyListProps {
    properties: Property[];
    db: Firestore;
    auth: Auth;
    fetchPropertiesFromFirebase: () => Promise<void>;
  }

interface PropertyCardProps {
    property: Property;
}

interface SelectedPropertyType {
    original_image: string[];
}

interface MapProps {
    properties: Property[];
}

const PropertyList: React.FC<PropertyListProps> = ({ properties, db, auth,fetchPropertiesFromFirebase }) => {
    const [expanded, setExpanded] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedProperty, setSelectedProperty] = useState<Property | null>(null);
    const [loadingImages, setLoadingImages] = useState<{ [key: number]: boolean }>({});
    const [priceRange, setPriceRange] = useState([0, 2000]);
    const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
    const locations = Array.from(new Set(properties.map(property => property.post_town)));
    const [selectedMapProperty, setSelectedMapProperty] = useState<Property | null>(null);
    const [showMap, setShowMap] = useState(false);
    const [renderMap, setRenderMap] = useState(false);
    const [bedroomFilter, setBedroomFilter] = useState<number | null>(null);
    const [bathroomFilter, setBathroomFilter] = useState<number | null>(null);
    const [listingOrder, setListingOrder] = useState<string>("");
    const [filteredProperties, setFilteredProperties] = useState(properties); // Add this line
    const [priceSortOrder, setPriceSortOrder] = useState('');
    const [isClearingFilters, setIsClearingFilters] = useState(false);
    const [isClearingData, setIsClearingData] = useState(false);
    const [bedroomOptions, setBedroomOptions] = useState<number[]>([]);
    const [bathroomOptions, setBathroomOptions] = useState<number[]>([]);



    const getUniqueValues = (properties: Property[], key: 'num_bedrooms' | 'num_bathrooms'): number[] => {
        const allValues = properties.map(property => property[key]);
        return Array.from(new Set(allValues)).sort((a, b) => a - b);
      };


    const handleBedroomChange = (event: any, newValue: number | null) => {
        setBedroomFilter(newValue);
    };

    const handleBathroomChange = (event: any, newValue: number | null) => {
        setBathroomFilter(newValue);
    };

    const handleListingOrderChange = (event: SelectChangeEvent) => {
        setListingOrder(event.target.value as string);
    };

    const handlePriceChange = (event: Event, newValue: number | number[]) => {
        setPriceRange(newValue as number[]);
    };

    // Handler for location change
    const handleLocationChange = (event: React.ChangeEvent<{}>, value: string[]) => {
        setSelectedLocations(value);
    };
    const handlePriceSortOrderChange = (event: SelectChangeEvent) => {
        setPriceSortOrder(event.target.value);
    };

    const applyFilters = () => {
        // Use a local variable to store the filtered properties
        let tempProperties = properties;
    
        // Apply all filters sequentially
        if (selectedLocations.length > 0) {
            tempProperties = tempProperties.filter(property =>
                selectedLocations.includes(property.post_town)
            );
        }
    
        if (bedroomFilter) {
            tempProperties = tempProperties.filter(property =>
                property.num_bedrooms === bedroomFilter
            );
        }
    
        if (bathroomFilter) {
            tempProperties = tempProperties.filter(property =>
                property.num_bathrooms === bathroomFilter
            );
        }
    
        if (priceRange[0] > 0 || priceRange[1] < 5000) {
            tempProperties = tempProperties.filter(property =>
                property.rental_prices.per_month >= priceRange[0] &&
                property.rental_prices.per_month <= priceRange[1]
            );
        }
    
        if (priceSortOrder === 'LowToHigh') {
            tempProperties.sort((a, b) => a.rental_prices.per_month - b.rental_prices.per_month);
        } else if (priceSortOrder === 'HighToLow') {
            tempProperties.sort((a, b) => b.rental_prices.per_month - a.rental_prices.per_month);
        }
    
        if (listingOrder) {
            tempProperties.sort((a, b) => {
                if (listingOrder === 'NewerToOlder') {
                    return new Date(b.listing_date).getTime() - new Date(a.listing_date).getTime();
                } else if (listingOrder === 'OlderToNewer') {
                    return new Date(a.listing_date).getTime() - new Date(b.listing_date).getTime();
                }
                return 0;
            });
        }
    
        // Update the state once after all filters are applied
        setFilteredProperties(tempProperties);
    };
    
    
    const clearFilters = () => {
        setIsClearingFilters(true);
        try {
            setSelectedLocations([]);
            setBedroomFilter(null);
            setBathroomFilter(null);
            setPriceRange([0, 5000]);
            setListingOrder("");
            setFilteredProperties(properties);
            setPriceSortOrder('');
        } catch (error) {
            console.log(error)
        }
        setIsClearingFilters(false);
    };
    useEffect(() => {
        let tempProperties = properties;
        setBedroomOptions(getUniqueValues(properties, 'num_bedrooms'));
        setBathroomOptions(getUniqueValues(properties, 'num_bathrooms'));
        setFilteredProperties(tempProperties);
        applyFilters()
      }, [properties, 
        selectedLocations, 
        bedroomFilter, 
        bathroomFilter, 
        priceRange, 
        priceSortOrder, 
        listingOrder]);
      
    useEffect(() => {
        if (selectedProperty) {
            // Initialize loading state for each image
            const initialLoadingState: { [key: number]: boolean } = {};
            selectedProperty.original_image.forEach((_, index) => {
                initialLoadingState[index] = true;
            });
            setLoadingImages(initialLoadingState);
        }
    }, [selectedProperty]);

    const handleImageLoad = (index: number) => {
        setLoadingImages(prev => ({ ...prev, [index]: false }));
    };

    const handleOpenDialog = (property: Property) => {
        setSelectedProperty(property);
        setOpenDialog(true);
    };
    const handleOpenMapDialog = (property: Property) => {
        setSelectedMapProperty(property);
        setOpenDialog(true);
    };
    const handleCloseMapDialog = () => {
        setOpenDialog(false);
        setSelectedMapProperty(null);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedProperty(null);
    };
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const handleMarkerClick = (property: Property) => {
        setSelectedMapProperty(property);
    };
    const renderTruncatedDescription = (description: string) => {
        return `${description.substring(0, 100)}...`;
    };
    const getPriceColor = (price: number) => {
        if (price < 1000) {
            return "green";
        } else if (price >= 1000 && price <= 1500) {
            return "orange";
        } else {
            return "red";
        }
    };
    //console.log(properties[0]);

    const clearUserData = async (db: Firestore, userId: string) => {
        if (!userId) {
            console.log('No user ID provided');
            return;
        }
    
        const propertiesCollectionRef = collection(db, `users/${userId}/properties`);
    
        try {
            // Get all documents in the user's properties collection
            const querySnapshot = await getDocs(propertiesCollectionRef);
    
            // Delete each document within the collection
            const deletePromises: Promise<void>[] = [];
            querySnapshot.forEach((doc) => {
                const deletePromise = deleteDoc(doc.ref);
                deletePromises.push(deletePromise);
            });
    
            // Wait for all deletions to complete
            await Promise.all(deletePromises);
            await fetchPropertiesFromFirebase();
            console.log('User data cleared');
        } catch (error) {
            console.error('Error clearing user data:', error);
        }
    };
    
    const handleClearDataClick = () => {
        setIsClearingData(true);
        if (auth.currentUser) {
            try {
                clearUserData(db, auth.currentUser.uid);
                
            } catch (error) {
                console.log(error)
            }
        }
        setIsClearingData(false);
    };
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API;
    if (!apiKey) {
        console.error("Google Maps API key is not defined in environment variables");
        return <div>Google Maps cannot be loaded. Please check the API key.</div>;
    }
    const getMapCenter = () => {
        if (filteredProperties.length === 0) {
            return { lat: 40.712776, lng: -74.005974 };
        }
        return {
            lat: filteredProperties[0].latitude,
            lng: filteredProperties[0].longitude
        };
    };

    const pinIcon = {
        path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5S13.38 11.5 12 11.5z', // SVG path for pin-like icon
        fillColor: '#FF8627', // Pin color
        fillOpacity: 1,
        strokeColor: '', // No border
        strokeWeight: 0,
        scale: 1,
    };
    const renderProperty = (property: Property) => (

        <Card sx={{ maxWidth: 345 }}>
            <CardMedia component={Carousel} sx={{ bgcolor: '#272727' }}>
                {property.images.map((image, index) => (
                    <img
                        key={index}
                        src={image["645x430"]}
                        alt={`Property Image ${index + 1}`}
                        style={{ width: '100%', height: 'auto' }}
                        onClick={() => handleOpenDialog(property)}
                    />
                ))}
            </CardMedia>
            <CardContent sx={{ bgcolor: '#272727', pb: 0 }}>

                <Typography gutterBottom variant="h5" component="div">
                    {property.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {renderTruncatedDescription(property.short_description.replace(/<\/?[^>]+(>|$)/g, ""))}
                </Typography>
                <Stack spacing={2} direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ py: 2 }}>
                    <Chip sx={{ m: 1, ml: 0, background: "transparent" }} icon={<BedroomIcon />} label={`${property.num_bedrooms} Bedrooms`} color="primary" />
                    <Chip sx={{ m: 0.5, background: "transparent" }} icon={<BathroomIcon />} label={`${property.num_bathrooms} Bathrooms`} color="primary" />
                    <Chip
                        sx={{
                            m: 1,
                            ml: 0,
                            background: getPriceColor(property.rental_prices.per_month)
                        }}
                        icon={<PriceIcon />}
                        label={`£${property.rental_prices.per_month}/month`}
                    />
                </Stack>
            </CardContent>
            <CardContent sx={{ bgcolor: '#272727', pt: 0 }}>
                <Typography variant="body2" color="text.secondary">
                    <Box display="flex" alignItems="center" gap={1}>
                        <LocationCityIcon />
                        <span>{property.displayable_address || `${property.post_town}, ${property.county}`}</span>
                    </Box>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    <Box display="flex" alignItems="center" gap={1}>
                        <EventNote />
                        <span>Listed: {property.listing_date}</span>
                    </Box>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    <Box display="flex" alignItems="center" gap={1}>
                        <List />
                        <span>Status: {property.listing_status}</span>
                    </Box>
                </Typography>
                {property.available_from_display && (
                    <Typography variant="body2" color="text.secondary">
                        <Box display="flex" alignItems="center" gap={1}>
                            <AccessTime />
                            <span>Available from: {property.available_from_display}</span>
                        </Box>
                    </Typography>
                )}
                <Typography variant="body2" color="text.secondary">
                    <Box display="flex" alignItems="center" gap={1}>
                        <Group />
                        <span>Shared: {property.rental_prices.shared_occupancy === 'Y' ? 'Yes' : 'No'}</span>
                    </Box>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    <Box display="flex" alignItems="center" gap={1}>
                        <Visibility />
                        <span>30-day views: {property.view_count_30day}</span>
                    </Box>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    <Box display="flex" alignItems="center" gap={1}>
                        <ConfirmationNumber />
                        <span>Listing ID: {property.listing_id}</span>
                    </Box>
                </Typography>
                <Link href={property.details_url} target="_blank" rel="noopener">
                    More Details
                </Link>
            </CardContent>
        </Card>
    );
    const renderMapProperty = (property: Property) => (
        <Card sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, maxWidth: 700, bgcolor: '#272727' }}>
            <Box sx={{ width: { xs: '100%', md: '50%' }, bgcolor: '#272727' }}>
                <CardMedia component={Carousel}>
                    {property.images.map((image, index) => (
                        <img
                            key={index}
                            src={image["645x430"]}
                            alt={`Property Image ${index + 1}`}
                            style={{ width: '100%', height: 'auto' }}
                            onClick={() => handleOpenMapDialog(property)}
                        />
                    ))}
                </CardMedia>
            </Box>
            <CardContent sx={{ width: { xs: '100%', md: '50%' }, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box>
                    <Typography gutterBottom variant="h5" component="div" color="text.primary">
                        {property.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {renderTruncatedDescription(property.short_description.replace(/<\/?[^>]+(>|$)/g, ""))}
                    </Typography>
                </Box>

                <Box>
                    <Stack spacing={2} direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ py: 2 }}>
                        <Chip sx={{ m: 1, background: "transparent" }} icon={<BedroomIcon />} label={`${property.num_bedrooms} Bedrooms`} color="primary" />
                        <Chip sx={{ m: 1, background: "transparent" }} icon={<BathroomIcon />} label={`${property.num_bathrooms} Bathrooms`} color="primary" />
                        <Chip
                            sx={{
                                m: 1,
                                background: getPriceColor(property.rental_prices.per_month)
                            }}
                            icon={<PriceIcon />}
                            label={`£${property.rental_prices.per_month}/month`}
                        />
                    </Stack>
                </Box>

                <Link href={property.details_url} target="_blank" rel="noopener" sx={{ mt: 2 }}>
                    More Details
                </Link>
            </CardContent>
        </Card>
    );
    const handleToggleMap = () => {
        setShowMap(!showMap);
        if (!showMap) {
            // Delay map render until after the Collapse transition (300ms by default)
            setTimeout(() => {
                setRenderMap(true);
            }, 300);
        } else {
            setRenderMap(false);
        }
    };
    const defaultCenter = getMapCenter();
    const isAnyFilterActive = selectedLocations.length > 0 || priceRange[0] !== 0 || priceRange[1] !== 5000 || listingOrder !== "" || priceSortOrder !== "";
    return (
        <>
            <Box sx={{ py: 2 }}>

                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            Location
                        </InputLabel>
                        <Autocomplete
                            multiple
                            fullWidth
                            options={locations}
                            getOptionLabel={(option) => option}
                            onChange={handleLocationChange}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Choose locations" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ width: '100%' }}>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Price Range: £{priceRange[0]} - £{priceRange[1]}
                            </InputLabel>
                            <Slider
                                value={priceRange}
                                onChange={handlePriceChange}
                                valueLabelDisplay="auto"
                                min={0}
                                max={5000}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            Bedrooms
                        </InputLabel>
                        <Autocomplete
                            fullWidth
                            options={bedroomOptions}
                            getOptionLabel={(option) => `${option} BR`}
                            onChange={handleBedroomChange}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            Bathrooms
                        </InputLabel>
                        <Autocomplete
                            fullWidth
                            options={bathroomOptions}
                            getOptionLabel={(option) => `${option} BA`}
                            onChange={handleBathroomChange}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Box sx={{ width: '100%' }}>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Listing Order
                            </InputLabel>
                            <Select
                                value={listingOrder}
                                onChange={handleListingOrderChange}
                                displayEmpty
                                fullWidth
                            >
                                <MenuItem value="NewerToOlder">Newer to Older</MenuItem>
                                <MenuItem value="OlderToNewer">Older to Newer</MenuItem>
                            </Select>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Box sx={{ width: '100%' }}>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Sort Price
                            </InputLabel>
                            <Select
                                value={priceSortOrder}
                                onChange={handlePriceSortOrderChange}
                                displayEmpty
                                fullWidth
                            >
                                <MenuItem value="LowToHigh">Low to High</MenuItem>
                                <MenuItem value="HighToLow">High to Low</MenuItem>
                            </Select>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center" justifyContent="space-between" pt={3}>
                    <Grid item xs={6} sm={6} md={6}>
                        {isAnyFilterActive && (
                            <LoadingButton
                                onClick={clearFilters}
                                variant="outlined"
                                loading={isClearingFilters}
                            >
                                Clear Filters
                            </LoadingButton>
                        )}
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <LoadingButton
                            onClick={handleClearDataClick}
                            variant="contained"
                            loading={isClearingData}
                        >
                            Clear My Data
                        </LoadingButton>
                    </Grid>
                </Grid>
                <ToastContainer position="top-center" />



            </Box>

            <Button
                startIcon={<ExpandMoreIcon />}
                onClick={handleToggleMap}
                aria-expanded={showMap}
                aria-label={showMap ? "close map" : "show map"}
            >
                <Typography>
                    {showMap ? "Close Map" : "Show Map"}
                </Typography>
            </Button>
            <Collapse in={showMap} timeout="auto" unmountOnExit>
                {renderMap && (
                    <LoadScriptNext googleMapsApiKey={apiKey}>
                        <GoogleMap
                            mapContainerStyle={{ width: '100%', height: '600px' }}
                            zoom={13}
                            center={defaultCenter}
                        >
                            {filteredProperties.map((property, index) => {
                                if (!property.latitude || !property.longitude) {
                                    console.error("Invalid property coordinates", property);
                                    return null;
                                }

                                return (
                                    <Marker
                                        key={index}
                                        position={{ lat: property.latitude, lng: property.longitude }}
                                        icon={pinIcon}
                                        onClick={() => handleMarkerClick(property)}
                                    />
                                );
                            })}

                            {selectedMapProperty && (
                                <InfoWindow
                                    position={{ lat: selectedMapProperty.latitude, lng: selectedMapProperty.longitude }}
                                    onCloseClick={() => setSelectedMapProperty(null)}
                                >
                                    <div>
                                        {/* Render the property details here */}
                                        <Grid container spacing={2} style={{ paddingTop: 16, paddingBottom: 16 }}>
                                            {/* Assuming renderProperty is a function to render a single property */}
                                            <Grid item xs={12}>
                                                {renderMapProperty(selectedMapProperty)}
                                            </Grid>
                                        </Grid>
                                    </div>
                                </InfoWindow>
                            )}
                        </GoogleMap>
                    </LoadScriptNext>
                )}
            </Collapse>
            <Grid container spacing={2} style={{ paddingTop: 16, paddingBottom: 16 }}>
                {filteredProperties.map((property, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        {renderProperty(property)}
                    </Grid>
                ))}
            </Grid>

            {selectedProperty && (
                <Dialog
                    fullScreen
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="property-images-dialog"
                    sx={{ height: "100%", bgcolor: '#272727' }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', bgcolor: '#272727' }}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCloseDialog}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Carousel sx={{ bgcolor: '#272727' }}>
                        {selectedProperty.original_image.map((image, index) => (
                            <Box key={index} position="relative" sx={{ height: "100%" }}>
                                {loadingImages[index] && (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        position="absolute"
                                        height="100%"
                                        top={0}
                                        left={0}
                                        right={0}
                                        bottom={0}
                                        bgcolor="rgba(255, 255, 255, 0.7)"
                                    >
                                        <CircularProgress />
                                    </Box>
                                )}
                                <img
                                    src={image}
                                    alt={`Property Image ${index + 1}`}
                                    style={{ width: '100%', height: 'auto' }}
                                    onLoad={() => handleImageLoad(index)}
                                />
                            </Box>
                        ))}
                    </Carousel>
                </Dialog>
            )}
            {selectedMapProperty && (
                <Dialog
                    fullScreen
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="property-images-dialog"
                    sx={{ height: "100%", bgcolor: '#272727' }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', bgcolor: '#272727' }}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCloseMapDialog}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Carousel sx={{ bgcolor: '#272727' }}>
                        {selectedMapProperty.original_image.map((image, index) => (
                            <Box key={index} position="relative" sx={{ height: "100%" }}>
                                {loadingImages[index] && (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        position="absolute"
                                        height="100%"
                                        top={0}
                                        left={0}
                                        right={0}
                                        bottom={0}
                                        bgcolor="rgba(255, 255, 255, 0.7)"
                                    >
                                        <CircularProgress />
                                    </Box>
                                )}
                                <img
                                    src={image}
                                    alt={`Property Image ${index + 1}`}
                                    style={{ width: '100%', height: 'auto' }}
                                    onLoad={() => handleImageLoad(index)}
                                />
                            </Box>
                        ))}
                    </Carousel>
                </Dialog>
            )}
        </>
    );
};

export default PropertyList;
