import React from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Typography,
  Button
} from "@mui/material";
import JobHunt from "../assets/JobHuntUK.png";
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import {SectionProps} from '../helpers/interfaces'


const projects = [
  {
    title: "Job Hunt UK",
    description:
      "Innovative job search application designed specifically for individuals seeking employment opportunities in their local area.",
    imageUrl: JobHunt,
    link: "https://jobhuntuk.com/",
  },
  {
    title: "Select AI",
    description:
      "Enhance browsing with Select AI. Select AI, the ultimate Chrome extension designed to enhance your browsing experience.",
    imageUrl:
      "https://lh3.googleusercontent.com/vuiyn0hzfxSLHqA3P3LWpIMS1aGuQ6DidCOpdn9KnXzBeaRxBc8Vz1jigCCV7xbJqQKNAabvO_F5C5hTxwaE7-ldfw=s1280-w1280-h800",
    link: "https://chromewebstore.google.com/detail/select-ai/akencabglgnbjflmlmflddmocjbeghld",
  },
  {
    title: "Modern Dropzone PCF Control",
    description:
      "A control to upload files using a modern interface. It integrates perfectly with tables that have notes enabled.",
    imageUrl: "https://pcf.gallery/assets/images/modern-dropzone-pcf.png",
    link: "https://pcf.gallery/modern-dropzone-pcf/",
  },
];
function SectionTitle({ title }: SectionProps) {
    return (
        <Box sx={{ mt: -1, display: 'flex', alignItems: 'baseline' }}>
          <WorkOutlineIcon sx={{ mr: 1 }} />
          <Typography 
            variant="h4" 
            component="h2" 
            gutterBottom 
            sx={{ 
              textAlign: 'left',
            }}
          >
            {title}
          </Typography>
        </Box>
      );
  }
function ProjectShowcase() {
  return (
    <Box sx={{ mt: { xs: 2, sm: 2 } }}>
    <SectionTitle title="Projects" />
    <Box sx={{ flexGrow: 1, py: 3 }}>
      <Grid container spacing={3}>
        {projects.map((project, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ background: "#272727" }}>
              <CardMedia
                component="img"
                height="240"
                image={project.imageUrl}
                alt={project.title}
                sx={{ background: "#272727" }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {project.title}
                </Typography>
                <Typography variant="body2" color="text.white">
                  {project.description}
                </Typography>
              </CardContent>
              <CardActions sx={{p:2}}>
                <Button variant="outlined" href={project.link} target="_blank" size="small">
                  Check it out
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
    </Box>
  );
}

export default ProjectShowcase;
